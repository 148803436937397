
    .select-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .avatar {
        width: 32px;
        height: 32px;
        max-width: 100%;
        max-height: 100%;
        border-radius: 50%;
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
    .up-img-show {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 50%;
        vertical-align: middle;
        margin-left: 10px;
        overflow: hidden;
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
