
    .qy-dialog-body {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        z-index: 2001;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .qy-dialog-wrap {
            box-sizing: border-box;
            background-color: #FFFFFF;
            width: 548px;
            border-radius: 2px;
            margin-top: 10%;
            padding-bottom: 10px;
            .qy-dialog-header {
                padding: 20px 20px 10px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #EAEAEA;
                .header-title {
                    font-size: 16px;
                }
                .header-act i {
                    font-size: 16px;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            .qy-dialog-footer {
                display: flex;
                justify-content: flex-end;
                height: 40px;
                padding-right: 20px;
                align-items: center;
            }
            .qy-dialog-main {
                padding: 10px 18px;
                .course-content {
                    min-height: 330px;
                    .course-list {
                        display: flex;
                        flex-wrap: wrap;
                        .course-item {
                            margin: 0 16px 20px 0;
                            .course-one {
                                position: relative;
                                border-radius: 5px;
                                overflow: hidden;
                                width: 160px;
                                height: 90px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                                .course-modal {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: rgba(0, 0, 0, 0.3);
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    p {
                                        width: calc(100% - 20px);
                                        text-align: center;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        color: #FFF;
                                    }
                                    .course-selected {
                                        position: absolute;
                                        top: 10px;
                                        right: 10px;
                                        width: 20px;
                                        height: 20px;
                                        text-align: center;
                                        line-height: 20px;
                                        border-radius: 10px;
                                        background-color: #409eff;
                                        i {
                                            color: #FFF;
                                        }
                                    }
                                }
                            }
                            &:nth-child(3n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .course-page {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
    .qy-dialog-modal {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
        background: #000;
        z-index: 2000;
    }

