
    .select-wrapper {
        display: flex;
        justify-content: space-between;
    }
    .up-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 32px;
        opacity: 0;
    }
    .up-img-show{
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #eeeeee;
        vertical-align: middle;
        margin-left: 10px;
    }
    img{
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
    ::v-deep .el-form-item__label {
        text-align: center;
    }

    .assign-project-list {
        display: flex;
        height: 450px;
        max-height: 450px;
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        .class-list {
            height: 100%;
            width: 100%;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    height: 100%;
                    /*.el-cascader-menu {*/
                        /*&:nth-child(2) {*/
                            /*::v-deep .el-cascader-node {*/
                                /*display: none;*/
                                /*&.is-active {*/
                                    /*display: block;*/
                                /*}*/
                            /*}*/
                        /*}*/
                    /*}*/
                }
            }
            .class-tree {
                /*width: 100%;*/
                padding: 15px;
                border: none;
                height: 100%;
                box-sizing: border-box;
                ::v-deep .el-cascader-menu__wrap {
                    height: 100%;
                }
            }
        }
    }
